//
//  Global Form Styles
//  ---------------------------------------------

input,
select,
textarea {
  @include text-style( $form-font-sizes, $color: $form-color );
}

input[type="text"],
input[type="date"],
input[type="search"],
input[type="datetime"],
input[type="week"],
input[type="month"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="tel"],
textarea {
  background-color: $form-bkg-color;
  padding: $form-padding;
  width: 100%;
  border: $form-border;
  border-radius: 0;
}

input[type="number"] {
  background-color: $form-bkg-color;
  padding: $form-padding;
  border: $form-border;
  border-radius: 0;
}

select {
  background-color: $form-bkg-color;
  appearance: none;
  border-radius: 0;
  padding: $form-padding;
  padding-right: 35px;
  width: 100%;
  border: $form-border;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB3aWR0aD0iMTc5MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQwOCA3MDRxMCAyNi0xOSA0NWwtNDQ4IDQ0OHEtMTkgMTktNDUgMTl0LTQ1LTE5bC00NDgtNDQ4cS0xOS0xOS0xOS00NXQxOS00NSA0NS0xOWg4OTZxMjYgMCA0NSAxOXQxOSA0NXoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  background-size: 20px;

  &::-ms-expand {
    display: none;
  }
}

textarea {
  height: 180px;
}

//
//  Gravity Form Styles
//  ---------------------------------------------

.gform_wrapper {
  .validation_error {
    @extend .alert;
    @extend .alert-danger;

    border-radius: 0;
  }

  .validation_message {
    @extend .alert;
    @extend .alert-danger;

    border-radius: 0;
    padding: 0.5em;
    margin-bottom: 0.5rem;
    font-size: 80%;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .gform_fields {
    display: flex;
    flex-wrap: wrap;
  }

  .gfield {
    margin-bottom: 15px;
    width: 100%;
  }

  .gfield_radio,
  .gfield_checkbox {
    input {
      margin-right: 6px;
      cursor: pointer;
    }
  }

  .ginput_complex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    > span {
      padding: 0 10px;
      width: 100%;

      @include screen(md) {
        @include flex(0, 0, 50%);
      }

      label {
        @extend .small;
      }
    }
  }

  .gform_footer {
    input[type=submit] {
      -webkit-appearance: none;
      -moz-appearance: none;
      cursor: pointer;

      @extend .btn;
      @extend .btn-primary;
    }
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}

.grecaptcha-badge {
  z-index: 900;
}

@media only screen and (max-width: 380px) {
  .ginput_recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}

