@include flex-sticky-footer();

html,
body {
  @include text-style($body-font-sizes, $body-font, $body-weight, $body-color, $body-line-height);
  @include smooth-fonts;

  background-color: $body-bkg;
  overflow-x: hidden;
}

body:not(.home) .wrap{
  background-color: $white;
}

img {
  max-width: 100%;
  height: auto;
}

.image__bkg {
  &-center {
    @include object-fit(center);
  }

  &-top {
    @include object-fit(top);
  }

  &-right {
    @include object-fit(right);
  }

  &-left {
    @include object-fit(left);
  }

  &-bottom {
    @include object-fit(bottom);
  }

  &-top-left {
    @include object-fit(top left);
  }

  &-top-right {
    @include object-fit(top right);
  }

  &-bottom-left {
    @include object-fit(bottom left);
  }

  &-bottom-right {
    @include object-fit(bottom right);
  }
}

h1,
.h1 {
  @include text-style($h1-font-sizes, $heading-font, $h1-weight, $heading-color, 1.2);
  font-style: italic;
}

h2,
.h2 {
  @include text-style($h2-font-sizes, $heading-font, $h2-weight, $heading-color, 1.2);
}

h3,
.h3 {
  @include text-style($h3-font-sizes, $heading-font, $h3-weight, $heading-color, 1.2);
  font-style: italic;
}

p,
li {
  @include text-style($body-font-sizes, $body-font, $body-weight, $body-color, $body-line-height);
}

a {
  @include links-transition(color);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

ul.nav a {
  display: block;
}

.panel {
  @include section-padding;
  &.services{
    padding-bottom: 0;
  }
}

.wp-block-embed.is-type-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.justify-content-center {
  margin: 0 auto;
}

.up-caret {
  @include fa-pseudo($icon: '\f0d8', $position: 'after', $weight: 700, $size: inherit, $color: inherit);
}

.down-caret {
  @include fa-pseudo($icon: '\f0d7', $position: 'after', $weight: 700, $size: inherit, $color: inherit);
}

.iframe-wrapper{
  text-align: center;
}

.page-content td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  vertical-align: top;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  @media (min-width: 768px){
    display: table-cell;
  }
}
.ty-wrapper {
  padding-top: 65px;
  margin: 0 auto;

  .ty-social {
      a {
          padding: 0 10px;

          i {
              font-size: 36px;
          }
      }
  }
}
/* App Full Width */
.tan-panel{
  background-color: $brand-secondary;
  padding: 50px 0;
}
.white-panel {
  padding: 50px 0;
}
/* App Full Width -END */

/* Front page */

.btn-red, .btn-accent{
  text-transform: uppercase;
  padding: 15.5px 30px;
  border-radius: 10px;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 17px;
  color: $white;
  background-color: $brand-primary;
  border-color: $brand-primary;
}

.why-choose {
  &.panel {
    padding-bottom: 10px;
    padding: 60px 0 25px 0;
  }

  .why-choose-container {
    @include hd {
      max-width: 1600px;
    }
  }

  .why-choose-title {
    margin-bottom: 60px;
    @media (max-width: 1199.9px){
      margin-bottom: 43px;
    }
    h1{
      font-size: 55px;
      font-style: italic;
      color: #393939;
      margin-bottom: 5px;
      span{
        color: $brand-primary;
      }
    }
    p{
      line-height: 1.9;
      width: 84%;
      margin: 0 auto;
      letter-spacing: -0.5px;

      @media (max-width: 1440px){
        width: 95%;
      }
    }
  }

  .features {
    .feature-row {
        justify-content: space-between;
        position: relative;

        & > div{
          margin-bottom: 42px;
        }
    }

    .feature {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 30px;
        padding: 0px 20px;

        @media (max-width: 1440px){
          flex-direction: column;
          align-items: center;
        }

      .feature-icon {
        img{
          max-width: 164px;
        }
      }

      .feature-content {
        @include tablet {
          width: 80%;
        }
        @media (max-width: 1440px){
          text-align: center;
        }

        .feature-title {
          font-family: $primary-font;
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 22px;
          font-size: 20px;
          margin-top: 5px;
          @media (max-width: 1199.9px){
            margin-bottom: 10px;
          }
        }

        hr {
          border-top: 2px solid $brand-primary;
          margin: 2px 0;
        }

        .feature-text {
          line-height: 1.5;
          margin-top: 8px;
          letter-spacing: -0.2px;
          @media (max-width: 1199.9px){
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.services{
  .service-item{
    background-color: $brand-secondary;
    padding: 65px 0 58px 0;
    @media (max-width: 1199.9px){
      margin-bottom: 0;
      padding: 60px 25px;
      &:last-child{
        padding-bottom: 60px;
      }
    }
    @media (max-width: 1570px){
      padding: 65px 25px 58px 25px;
    }
    .container{
      @media (min-width: 993px){
        max-width: 1540px;
        padding: 0 10px;
      }
      .row{
        align-items: center;
        @media (max-width: 992px){
          gap: 25px;
        }
        .image-section{
          padding: 0;
          @media (max-width: 992px){
              margin: 0 auto;
              padding: 0 25px;
          }
          img{
            border: 5px solid $brand-primary;
            border-radius: 25px;
          }
        }

        .text-section{
          @media (max-width: 992px){
            max-width: 100%;
            flex: 0 0 100%;
          }
          @media (min-width: 992px) and (max-width: 1460px){
            padding-left: 40px;
          }
          .wrapper{
            @media (max-width: 992px){
              text-align: center;
            }
            h1{
              font-style: italic;
              color: $gray;
              span{
                color: $brand-primary;
              }
            }
            .desc {
              padding: 10px 0;
              margin-bottom: 16px;
              line-height: 1.5;
              @media (max-width: 992px){
                padding: 10px 0 5px 0;
              }
            }
          }
        }
      }
    }
 //   @media (min-width: 993px){
    &.image_on_right{
      background-color: unset;
      .row{
        flex-direction: row-reverse;
        .text-section{
        }
        .image-section{
          text-align-last: end;
        }
      }
    }
//  }
  }
}

/* Testimonials */
#testimonials-panel, #testimonials-panel-adv {
  background-repeat: no-repeat;
  background-size: cover;
  &.panel {
    @include hd {
      padding: 50px 0 65px 0;
    }
  }
  .h1{
    font-style: italic;
    font-weight: 500;
    font-size: 55px;
    color: $gray;
    span{
      color: $brand-primary;
    }
  }

  .sub-heading{
    font-size: 15px;
  }

  .testimonials-carousel {
    @include tablet {
      margin-bottom: 20px;
      margin-top: 35px;
    }

    @include laptop {
      margin-bottom: 42px !important;
      margin-top: 65px;
    }
  }

  .icon {
    width: 65px;
    height: 65px;
  }

  .testimonial {
    .container {
      @include laptop {
        padding: 0 40px;
      }
  
      &.first-item{
        padding-left: 18px;
      }
    }
    .quote-header{
      display: flex;
      gap: 20px;
      align-items: center;
      @media (max-width: 768px){
        justify-content: center;
      }
      .quote-title{
        width: 220px;
        .author {
          font-size: 21px;
          font-weight: 600;
          margin-bottom: 0;
        }
        p{
          font-size: 18px;
          font-style: italic;
          text-transform: uppercase;
          margin-bottom: 0;
        }
      }
    }
    .quote-seperation{
      content: url('./images/quote_line.webp');
      margin-left: -10px;
      margin-top: 8px;
      margin-bottom: 18px;
      @media (max-width: 768px){
        margin: 0 auto;
      }
    }
    .quote-desc {
      p{
        line-height: 1.5;
      }
      @media (max-width: 768px){
        margin-top: 25px;
        text-align: center;
      }
    }
  }


}


.slider-nav {
  position: relative;
  display: inline-block;
  padding: 0 23px;

  .slick-dots {
    position: static;
    width: auto;
    display: flex !important;
    align-items: center;

    li,
    button,
    button::before {
      width: auto;
      height: auto;
    }

    li {
      margin: 0 5px;
    }

    button::before {
      content: '\f2f8';
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-size: 25px;
      font-weight: 700;
      position: relative;
      opacity: 1;
      color: #434343;
    }

    .slick-active button::before {
      content: '\f111';
      color: $brand-primary;
      opacity: 1;
    }
  }

  .slick-arrow {
    height: auto;
    width: auto;

    &::before {
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-weight: 700;
      font-size: 26px;
      color: #434343;
      opacity: 1;
    }

    &.slick-prev {
      left: -15px;

      &::before {
        content: '\f359';
      }
    }

    &.slick-next {
      right: -15px;

      &::before {
        content: '\f35a';
      }
    }
  }
}
/* Testimonials */

/* Front page */

/* Directory Page */

.teritory {
  background: #000; 
  color: #fff;
  font-weight: 700;
  font-family: $primary-font;
}

ul.coverage-area {
  list-style-type: none;
  padding-left: 10px;
}

h3.location-list,
a.state-link {
  color: #800000;
  font-weight: 700;
  font-size: 16px;
  font-family: $primary-font;
}

h3.location-list {
  text-transform: uppercase;
  font-style: normal;
}

a.state-link {
  text-align: center;
}

a.phone {
  color: black;

  &:hover {
    color: black;
  }
}

.state-footer-title {
  font-weight: 700;
  font-size: 16px;
  font-family: $primary-font;
  text-align: center;
}

.dark-red {
  color: #800000;
}

.subpage-list {
  column-count: 2;

  @include tablet {
    column-count: 3;
  }

  @include laptop {
    column-count: 4;
  }
}

/* Directory page */

/* Inner hero */

.int-hero-wrapper {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.35);
  background-blend-mode: overlay;

  #int-hero-container,
  #int-hero-container-desktop {
    position: relative;
    z-index: 1;

    @include tablet {
      margin-top: 180px;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    h3 {
      color: $white;
    }
  }

  #int-hero-container {
    display: flex !important;

    @include tablet {
      display: none !important;
    }
  }

  #int-hero-container-desktop {
    display: none !important;

    @include tablet {
      display: flex !important;
    }
  }

  &:not(.int-strapline-wrapper) {
    min-height: 470px;
    max-height: 600px;

    @include tablet {
      min-height: 570px;
      margin-bottom: 20px;
    }

    @include laptop {
      min-height: 800px;
    }

    @include desktop {
      min-height: 600px;
    }
    @media (max-width: 1200px){
      flex-direction: column;
      justify-content: center;
      min-height: 450px;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(xs){
      justify-content: flex-end;
    }
  }

  &.no-strapline{
    &:not(.int-strapline-wrapper) {
      @include desktop {
        min-height: 470px;
      }
    }
    #int-hero-container,
    #int-hero-container-desktop {
      @include tablet {
        margin-top: 0;
      }
    }
    
  }
}

.iframe-wrapper.about-us {
  iframe{
    @media (min-width: 1200px){
      width: 100% !important;
    }
    @media (max-width: 600px){
      width: 100% !important;
    }
  }
}

.page-content-panel td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  vertical-align: top;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  @media (min-width: 978px){
    display: table-cell;
  }
}

.line2 span[registered] {
  position: relative;
  top: 3px;
  font-size: 28px;
}