.btn {
    margin-top: 7px;
    padding: 14px 30px;
    border: 2px solid $brand-primary;
    text-transform: uppercase;
    border-radius: 7px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: $white;
    font-size: 17px;
    transition: all 0.6s ease;

    &:not(:first-of-type) {
        @include tablet {
            margin-left: 15px;
        }

        &:active, &:hover{
            background-color: #fff !important;
            border-color: #af6922 !important;
            color: $brand-primary;
        }
    }
}
.btn-tertiary {
    background-color: #fff;
    color: $brand-primary;
}

.wp-block-search__inside-wrapper {
    display: flex;
    align-items: center;
}

button, [type=button], [type=reset], [type=submit]{
    background-color: $brand-primary;
    border-color: $brand-primary;
    padding: 12px 30px;
    border: 2px solid $brand-primary;
    text-transform: uppercase;
    border-radius: 7px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: $white;
    font-size: 17px;
    transition: all 0.5s ease;
    &:hover {
        background-color: $hover-primary;
    }
}
.btn:hover, .gform_wrapper .gform_footer input[type=submit]:hover, .comment-form input[type=submit]:hover{
    color: $white;
    background-color: $hover-primary !important;
}

.slick-dots li button{
    &:hover{
        background-color: transparent;
    }
}

.widget_search .wp-block-search__button {
    margin-left: 5px;
}

.hero-content{
    .btn-primary, .btn-accent{
        border: 2px solid $brand-secondary;
        color: $brand-secondary;
    }
}
