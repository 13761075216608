a.scrollup {
    position: fixed;
    z-index: 1000;
    bottom: 20px;
    right: 20px;
    display: none;
    background-color: rgba($brand-primary, 0.7);
    border: 1px solid $brand-primary;
    padding: 0;
    height: 40px;
    width: 40px;
  
    &:hover,
    &:focus {
      color: white;
      background-color: darken($brand-primary, 10%);
      text-decoration: none;
    }
  
    &::before {
      border-style: solid;
      border-width: 3px 3px 0 0;
      content: '';
      display: inline-block;
      height: 12px;
      width: 12px;
      top: 6px;
      left: 13px;
      position: relative;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      vertical-align: middle;
      border-color: #fff;
    }
  }
  
  