#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 10;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
    font-family: $secondary-font;
  }

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}


header.banner{
  position: relative;
  background: #e5dbc5;

  .header-decoration {
      background-image: url('./images/header_swirl.png');
      bottom: -22px;
  }
  .mobile-menu{
    @media (min-width: 360px){
      display: flex;
      align-items: center;
      gap: 50px;
      z-index: 3;
    }
    @media (max-width: 610px){
      flex-direction: column;
      align-items: end;
      gap: 0;
      display: flex;
    }
    .no-hover-phone{
      @media (max-width: 1200px){
        &:hover{
          color: $brand-primary;
        }
      }
      @media (max-width: 500px){
        font-size: 17px;
      }
      @media (max-width: 420px){
        font-size: 16px;
      }
      font-size: 18px;
      font-weight: 600;
      .fas{
        padding-right: 5px;
      }
    }
    
    @media (min-width: 1200px){
      display: none;
    }
  }
  .header-wrapper{
    padding-left: 28px;
    padding-right: 0;
    @media (max-width: 1200px){
      align-items: center !important;
    }
    @media (min-width: 1200px) and (max-width: 1299.9px){
      max-width: 90%;
    }
    @media (min-width: 1200px) and (max-width: 1439.9px){
      max-width: 95%;
    }
    @include media-breakpoint-down(md){
      max-width: 92%;
    } 
    @include media-breakpoint-down(xs){
      padding-left: 0;
    }
    
      .brand{
        position: relative;
        z-index: 5;
        padding: 12px 0 0 0;

        @media (max-width: 500px){
          padding: 20px 0 20px 0;
        }

        img{
          @media (max-width: 1440px){
            max-width: 270px;
          }
          @media (max-width: 500px){
            max-width: 220px;
          }
          @media (max-width: 500px){
            max-width: 125px;
          }
        }
        &:before{ 
          background-image: url('./images/top_header_arc.webp');
          background-position: 0 30%;
          bottom: 0;
          content: "";
          position: absolute;
          right: -110px;
          top: -6px;
          width: 115px;
          z-index: -1;
          background-repeat: no-repeat;
          height: 170px;

          @media (max-width: 500px){
            right: -45px;
          }
         }
      }
    
    .header-menu{
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @media (max-width: 1199.99px){
        display: none;
      }

      .nav-full-width-container{
        @media (min-width: 1200px) and (max-width: 1600px) {
          justify-content: flex-end;
        }
      }

      li{
        a{
          font-weight: 600;
          font-size: 17.5px;
          letter-spacing: 0.1px;
        }
      }
      .nav-top{
        position: relative;
        gap: 22px;
        align-self: flex-end;
        z-index: 2;
        width: 100%;
        justify-content: flex-end;
        @media (min-width: 1200px) and (max-width: 1600px) {
          gap: 0;
        }

        &:before{
          height: 76px;
          bottom: 0;
          content: "";
          left: 0;
          position: absolute;
          right: -100vw;
          top: 0;
          z-index: -1;
          background-color: $brand-primary;
        }
        li{
          padding: 13px 17px;
          &:last-child{
            padding-right: 0;
            a:before{
              content: none;
            }
          }
          a{
            color: $white;
            padding: 0.7rem 17px;
            position: relative;
            font-weight: 500;
            @media (min-width: 1200px) and (max-width: 1439.9px) {
              font-size: 16px;
            }
            &:before{
              position: absolute;
              bottom: 0;
              height: 3px;
              width: 100%;
              content: "";
              left: 0;
              right: 0;
              background: $white;
              transform: scaleX(0);
              transition: all 0.65s ease;

            }
            &:hover{
              &:before{
                transform: scaleX(1);
                transition: all 0.5s ease;
              }
            }
          }
      }
    }

      .nav-full-width-container {
        justify-content: flex-end;
        .nav-primary {
          position: relative;
          z-index: 2;
          gap: 50px;
          padding-top: 12px;
          @media (min-width: 1200px) and (max-width: 1600px) {
            gap: 0;
            padding: 0;
          }

          a {
            color: $black;
            transition: all 0.5s ease-in-out;
            padding: 17px;
            text-transform: uppercase;
            position: relative;
            @media (min-width: 1200px) and (max-width: 1290px) {
              font-size: 15px;
              padding: 17px 10px;
            }
            @media (min-width: 1290.1px) and (max-width: 1439px) {
              font-size: 16px;
              padding: 17px 12px;
            }
            &:before{
              position: absolute;
              bottom: 0;
              height: 3px;
              width: 100%;
              content: "";
              left: 0;
              right: 0;
              background: $brand-primary;
              transform: scaleX(0);
              transition: all 0.65s ease;

            }
            &:hover{
              &:before{
                transform: scaleX(1);
                transition: all 0.5s ease;
              }
            }
          }
        }
      }
    }
  }
}

.header-decoration, .footer-decoration, .content-decoration {
  background-image: url('./images/footer_swirl.png');
  position: absolute;
  width: 100%;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

/* Sub Menu */

li.menu-item-has-children.open .sub-menu {
  visibility: visible;
  opacity: 1;
  min-width: 300px;

 /* li:not(:last-child):after{
    content: '';
  } */
}
.nav-primary li.menu-coffee-news-publisher-directory.open .sub-menu {
  width: 470px;
}

.sub-menu {
  visibility: hidden;
  opacity: 0;
  list-style: none;
  padding: 0;
  z-index: 1;

  @include links-transition((visibility, opacity, top));
}

.nav-top .sub-menu {
  margin-top: 19px;
}

.nav-primary .sub-menu {
  margin-top: 7px;
}

.banner .sub-menu {
  background-color: $white;
  position: absolute;

  a {
    color: $black;
    padding: 20px 15px 0;
    &:hover {
      color: #fff !important;
      background: $brand-primary;
  }
  }
}

.banner,
.sideNav {
  li.menu-item-has-children>a {
    @extend .down-caret;

    &::after {
      margin-left: 9px;
      position: relative;
    }
  }

  li.menu-item-has-children.open>a {
    @extend .up-caret;
  }
}
/* Sub Menu */
.menu-coffee-news-publisher-directory{
  .external{
    position: relative;
    &:after {
      content: "\F08E";
      font-family: "Font Awesome 5 Pro",serif;
      position: absolute;
      right: 30px;
      top: 19px;
      font-size: 14px;
    }
  }
}