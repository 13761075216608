@import "common/variables";
@import "common/mixins";

/** Import everything from autoload */
@import "./autoload/_bootstrap.scss";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

/** Import theme styles */
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "components/sidenav";
@import "components/scroll";
@import "components/hamburger";
@import "components/hero";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/review-us.scss";
@import "~@fortawesome/fontawesome-pro/css/all.css";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
